import React from 'react';
import { render } from 'react-dom';

import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import settings from 'settings';

import App from './app';

import configureAppStore from './store/configureAppStore';

import registerServiceWorker from './registerServiceWorker';

function loadScriptGA(id) {
  if (id) {
    // Adding the script tag to the head as suggested before
    // const NONCE = '/Ik25tsvgBYXMZuQnJRE6Q==';
    const { head, body } = document;

    const script = document.createElement('script');
    // script.setAttribute('nonce', NONCE);
    script.innerHTML = `
        (function (w, d, s, l, i) {
        w[l] = w[l] || []; w[l].push({
          'gtm.start':
            new Date().getTime(), event: 'gtm.js'
        }); var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
            'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', '${id}');
      `;

    const noscript = document.createElement('noscript');
    noscript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${id}" height="0" width="0"
        style="display:none;visibility:hidden"></iframe>`;
    // Fire the loading
    head.appendChild(script);
    body.appendChild(noscript);
  }
}

const container = document.getElementById('root');
const store = configureAppStore();

const { GA_ID } = settings;
loadScriptGA(GA_ID);

render(
  <Provider store={store}>
    <Router>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </Router>
  </Provider>,
  container,
);

registerServiceWorker();
