import isEmpty from 'lodash/isEmpty';

export const sendUserID = uid => {
  if (!isEmpty(window.dataLayer)) {
    window.dataLayer.push({
      event: 'SEND_USER_ID',
      uid,
    });
  }
};
